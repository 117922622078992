import React, { useState, useEffect } from 'react';
import essayData from './data/essay_responses.json';
import PurchaseButton from './PurchaseButton';
import { Helmet } from 'react-helmet';

const ResponseExamples = () => {
  const [selectedTopic, setSelectedTopic] = useState(essayData[0]);

  // Default selection on component mount
  useEffect(() => {
    setSelectedTopic(essayData[0]);
  }, []);

  // Helper function to render text with paragraphs
  const renderTextWithParagraphs = (text) => {
    return text.split('\n\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  return (
    <>

    <Helmet>
      <title>GRE Writing Prep - Get Example High-Scoring GRE Essay Responses</title>
      <meta name="description" content="Get instant access to our comprehensive PDF document with 156 high-score essay examples covering real GRE analytical writing topics, showcasing a variety of writing styles and strategies to help you excel." />
    </Helmet>

    <div className="topic-selector-container">
      <section id="purchase-section">
        <PurchaseButton />

        <h3 class="response_header">Sample High-Score Essays</h3>

        <p class="response_paragraph">Example #1:</p>
        <blockquote class="response_quote">
          "{essayData[0].Topic}"
        </blockquote>
        <p>Task: {essayData[0].Task}</p>
        <p><strong>Sample Response:</strong>{renderTextWithParagraphs(essayData[0].Response)}</p>

        <p class="response_paragraph">Example #2:</p>
        <blockquote class="response_quote">
          "{essayData[1].Topic}"
        </blockquote>
        <p>Task: {essayData[1].Task}</p>
        <p><strong>Sample Response:</strong>{renderTextWithParagraphs(essayData[1].Response)}</p>

        <p class="response_paragraph">Example #3:</p>
        <blockquote class="response_quote">
          "{essayData[2].Topic}"
        </blockquote>
        <p>Task: {essayData[2].Task}</p>
        <p><strong>Sample Response:</strong>{renderTextWithParagraphs(essayData[2].Response)}</p>

        <div>
          <a href="#purchase-section" class="response_link">Get Full Access to All 156 Essays for $15 &rarr;</a>
        </div>
        </section>
    </div>
    </>
  );
};

export default ResponseExamples;
