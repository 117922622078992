import React from 'react';

export default function SalesText() {
  return (
    <div>
        <h2 class="sales_header">Master the GRE Analytical Writing Section</h2>
        <p class="sales_paragraph">
            Struggling to achieve a high score on the GRE Analytical Writing section? Get instant access to our comprehensive PDF document with <strong>156 high-score essay examples</strong>. These essays cover the response to real GRE analytical writing topics, showcasing a variety of writing styles and strategies to help you excel.
        </p>
        <ul class="sales_list">
            <li class="sales_bullet">
                <span class="sales_checkmark">✔</span> 156 essays scoring 6.0 in our tool
            </li>
            <li class="sales_bullet">
                <span class="sales_checkmark">✔</span> Analytical writing topics from GRE topics pool
            </li>
            <li class="sales_bullet">
                <span class="sales_checkmark">✔</span> Learn key strategies and effective writing techniques
            </li>
            <li class="sales_bullet">
                <span class="sales_checkmark">✔</span> Instant download after secure payment
            </li>
        </ul>
        <p class="sales_price">Price: $15</p>
    </div>
  );
}

