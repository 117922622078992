import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import SalesText from './SalesText';

const PurchaseButton = () => {
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [buyerEmail, setBuyerEmail] = useState('');

  const PayPal_test_key = 'AaelYKDPNpvCfi1jwiY3UWMaVJP5qGFGxVOO6_lnEpTm8ta_W57Qdak59YZIrrUkDlm4gOGcHp_cQzLJ';
  const PayPal_prod_key = 'Ae1lxo-M5XxLZsNPvRy9FN6IkPvMUqkGqR76k0s2qXzWQNH7ow3ypQIvDlsbt-KbtdHmKvMeCo4JACGz';

  // Reset isPaid to false when the page is refreshed
  useEffect(() => {
    setIsPaid(false);
  }, []);

  // Handler for payment approval
  const handleApprove = async (data, actions) => {
    try {
      // Capture the payment details
      const details = await actions.order.capture();
      const buyerName = details.payer.name.given_name;

      // Extract the buyer's email address from the captured order details
      const buyerEmailAddress = details.payer.email_address;
      setBuyerEmail(buyerEmailAddress);

      // alert(`Thank you for your purchase, ${buyerName}! Your email: ${buyerEmailAddress}`);

      // Send the email to customer
      const response = await fetch(`https://ik9ggnv03c.execute-api.us-east-1.amazonaws.com/Prod/get-dynamic-url?email=${buyerEmailAddress}`)
      if (!response.ok) {
        throw new Error('Failed to send an email.');
      }

      // Fetch the pre-signed URL for the PDF file from AWS S3
      /*
      const response = await fetch('https://your-api-endpoint.com/get-presigned-url');
      if (!response.ok) {
        throw new Error('Failed to fetch the download URL.');
      }

      const data = await response.json();
      setDownloadUrl(data.url);
      */
      setDownloadUrl("http://test.com");
      
      // Hide the PayPal button after payment
      setIsPaid(true);
    } catch (error) {
      console.error('Payment approval error:', error);
      alert('There was an issue completing your purchase.');
    }
  };

  return (
    <div>
      <SalesText />
      {!isPaid && (
        <PayPalScriptProvider options={{ 
            'client-id': PayPal_prod_key,
            'disable-funding': 'paylater' // Disable "Pay Later" button
         }}>
         <div style={{ textAlign: 'center', width: '300px', margin: '0 auto' }}>
          <a>Purchase Now: </a>
          <div>
            <PayPalButtons
             style={{ layout: 'horizontal', tagline: false, height: 25 }} // Small button
             createOrder={(data, actions) => {
               return actions.order.create({
                  purchase_units: [{
                   amount: {
                      value: '15.00', // Set price to $15
                    },
                  }],
               });
              }}
              onApprove={handleApprove}
           />
          </div>
        </div>
        </PayPalScriptProvider>
      )}
      {!isPaid && (<p class="sales_email">Note: You will receive an email with attached file immediately after completing your payment.</p>)}

      {isPaid && (
        <div style={{ marginTop: '10px' }}>
          <p class="sales_paragraph">File successfully emailed to {buyerEmail}!</p>
        </div>
      )}

      <hr class="sales_line"></hr>

    </div>
  );
};

export default PurchaseButton;
