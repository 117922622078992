import React from 'react';
import { BrowserRouter as Router, Link} from 'react-router-dom';

export default function Header() {
  return (
    <header>
        <h1><a class="link" href="/">SCORED PRACTICE FOR GRE ANALYTICAL WRITING TASK</a></h1>
        <nav>
          <Link to="/about">ABOUT THE WEBSITE</Link>
          <Link to="/how-it-works">HOW DOES IT WORK</Link>
          <Link to="/response-examples">GET ESSAY EXAMPLES</Link>
        </nav>
    </header>
  );
}



