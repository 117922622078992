import React from 'react';
import WritingPrompt from './components/WritingPrompt';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
        <>
        <Helmet>
            <title>GRE Writing Prep - Scored Practice Tests for GRE Analytical Writing Task</title>
            <meta name="description" content="Online practice to prepare for GRE Analytical Writing with scored assessments of your essays and insightful feedback on your performance, helping you improve your writing skills and prepare effectively for the exam." />
        </Helmet>
        <WritingPrompt />
        </>
    )
}